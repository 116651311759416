<div class="modal-header bg-primary">
  <h5 class="modal-title">
    {{ reactions ? ('feedback.user-list-modal.Réactions' | translate) : ('feedback.user-list-modal.Personnes ayant reçu le feedback' | translate) }}
  </h5>
  <button class="btn-close" type="button" data-bs-dismiss="modal" (click)="modal.dismiss()" aria-label="Close"></button>
</div>
<div class="container-fluid py-3">
  @for (user of users; track user; let first = $first) {
    <div class="row align-items-center" [class.mt-3]="!first">
      <div class="col-auto">
        <hiji-user-avatar
          style="width: 40px; height: 40px"
          [idUser]="user.idUser"
          [avatarExtension]="user.avatarExtension"
          [defaultBlueAvatar]="true"
        ></hiji-user-avatar>
      </div>
      <div class="col">{{ user.firstName }}&nbsp;{{ user.lastName }}</div>
      @if (showVu) {
        <div class="col-auto text-end"><i class="fa-light" [class.fa-eye]="user.vu" [class.fa-eye-slash]="!user.vu"></i></div>
      }
    </div>
  }
</div>
